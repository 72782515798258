"use client";
import { useRef } from "react";
import FactoidBar from "@/components/CourseContent/ContentRefresh/FactoidBar";
import { ProgramDetailContentProps } from "../types";
import InstructorList from "@/components/CourseContent/ContentRefresh/InstructorList";
import ProgramContent from "@/components/CourseContent/ContentOverview";
import WhatYoullLearn from "@/components/CourseContent/ContentRefresh/WhatYoullLearn";
import {
  fullWidthContainer,
  mainContent,
  mainContentSideRail,
  maxWidthContainer,
} from "@/components/CourseContent/styles/constants";
import styles from "@/components/CourseContent/styles/contentHero.module.css";
import { getAllInstructorsFromCourse } from "@/lib/instructor/getAllInstructorsFromCourse";
import MidPageCTA from "./MidPageCTA";
import ProgramHeader from "@/components/ProgramDetailLayout/ProgramDetailRefresh/ProgramHeader";
import getProgramDuration from "@/lib/program/utils/getProgramDuration";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import getProgramFromSubject from "@/components/ProgramDetailLayout/ProgramDetailRefresh/utils/getSubjectFromProgram";
import CategoryCTA from "./CategoryCTA";
import ProgramRelatedContent from "@/components/ProgramDetailLayout/ProgramDetailRefresh/ProgramRelatedContent";
import { CourseVideo } from "@/components/CourseContent/ContentOverview/types";
import ProgramEnroll from "./ProgramEnroll";
import IndustryInsights from "./IndustryInsights";
import checkRTL from "@/utils/functions/checkRTL";
import sortBySortValue from "@/utils/functions/sortBySortValue";
import ProgramSummaryPathway from "./ProgramSummaryPathway";
import { SITE_URL } from "@/lib/program/utils/seo/generateProgramSeo";
import SocialShare from "@/components/CourseContent/ContentRefresh/SocialShare";
import ProgramEndorsements from "@/components/ProgramDetailLayout/ProgramDetailRefresh/ProgramEndorsements";
import OFACmessage from "./OFACmessage";
import ProgramStickyCTA from "./ProgramStickyCTA";
import useProgramRedesignTraffic from "@/monarch/rules/programRedesignTraffic/useProgramRedesignTraffic";
import ProgramHeroSideRailContainer from "../ProgramHeroSideRail/ProgramHeroSideRailContainer";

export default function ProgramDetailContent({
  programData,
  courses,
  pricingData,
  skus,
  programIsEnrollable,
}: ProgramDetailContentProps) {
  const { sectionsOrder, headerCta, showSideRail, isLoading, factoidsBar } =
    useProgramRedesignTraffic();
  const { expectedLearningItems } = programData;
  const scrollFollowerRef = useRef<HTMLDivElement>(null);
  const sittingStillRef = useRef<HTMLDivElement>(null);
  const scrollingLimitRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const programOverviewVideo =
    programData.video.length > 0
      ? (programData.video[0] as CourseVideo)
      : undefined;
  if (programOverviewVideo) {
    // Program videos do not have a thumbnail image, this one was provided as fallback by the design team
    programOverviewVideo.image = {
      src: "/bg-images/video-player-thumbnail.jpg",
    };
  }
  const programSubject = getProgramFromSubject(programData);
  programData.video.length > 0
    ? (programData.video[0] as CourseVideo)
    : undefined;
  const instructors = getAllInstructorsFromCourse(courses);

  const formattedInsights = programData.jobOutlookItems.map((item) => ({
    value: item.value ?? "",
  }));

  const duration = courses && getProgramDuration(courses);
  const filteredExpectedLearningItems = sortBySortValue(
    expectedLearningItems.filter((item) => item.value),
  );
  const areLearningItemsRTL = checkRTL(
    filteredExpectedLearningItems.map((item) => item.value).join(""),
  );

  const componentOrder = [
    { key: "whatYoullLearn", order: sectionsOrder?.whatYoullLearn ?? 1 },
    { key: "programOverview", order: sectionsOrder?.programOverview ?? 2 },
    {
      key: "programSummaryPathway",
      order: sectionsOrder?.programSummaryPathway ?? 3,
    },
    { key: "midPageCTA", order: sectionsOrder?.midPageCTA ?? 4 },
    { key: "instructorList", order: sectionsOrder?.instructorList ?? 5 },
    { key: "industryInsights", order: sectionsOrder?.industryInsights ?? 6 },
    {
      key: "programEndorsements",
      order: sectionsOrder?.programEndorsements ?? 7,
    },
    { key: "creditPathways", order: sectionsOrder?.creditPathways ?? 8 },
    { key: "programEnroll", order: sectionsOrder?.programEnroll ?? 9 },
    {
      key: "programRelatedContent",
      order: sectionsOrder?.programRelatedContent ?? 10,
    },
  ];

  const sortedComponents = componentOrder.sort((a, b) => a.order - b.order);
  const headerContainerClasses = showSideRail ? "xl:flex xl:gap-6" : "";
  const bodyContainerClasses = showSideRail ? "xl:w-[1280px] xl:mx-auto" : "";

  return (
    <>
      <div
        className={`${
          headerCta?.bgColor !== "light" ? styles.contentHero : "bg-putty-100"
        } pb-12 pt-20 sm:py-12`}
      >
        <div className={`${maxWidthContainer} ${fullWidthContainer} gap-8`}>
          <Breadcrumbs
            path={programData?.marketingUrl || ""}
            pageName={programData.title}
            containerClassName="not-prose"
            linksToShowOnSmallScreen={1}
          />
          <div className={headerContainerClasses}>
            <div
              ref={headerRef}
              className={`flex flex-col gap-8 ${showSideRail ? "xl:max-w-[824px]" : ""}`}
            >
              <ProgramHeader
                pricingData={pricingData}
                duration={duration}
                programIsEnrollable={programIsEnrollable}
                skus={skus}
                courses={courses}
                programData={programData}
                showSideRail={showSideRail}
              />
              <div className="w-full">
                <FactoidBar
                  courseData={courses}
                  isFactoidsBarFieldsLoading={isLoading}
                  monarchFactoidsBar={factoidsBar}
                />
              </div>
            </div>
            {showSideRail && (
              <ProgramHeroSideRailContainer
                headerRef={headerRef}
                scrollFollowerRef={scrollFollowerRef}
                sittingStillRef={sittingStillRef}
                scrollingLimitRef={scrollingLimitRef}
                programData={programData}
                courses={courses}
                pricingData={pricingData}
                skus={skus}
                programIsEnrollable={programIsEnrollable}
              />
            )}
          </div>
        </div>
      </div>
      {programData.programEligibleForOneClickPurchase &&
      programIsEnrollable &&
      !showSideRail ? (
        <ProgramStickyCTA
          programData={programData}
          courses={courses}
          pricingData={pricingData}
          skus={skus}
        ></ProgramStickyCTA>
      ) : null}
      <div className="bg-putty-100 pt-12 md:pt-16 flex flex-col gap-12 sm:gap-16">
        <div className={bodyContainerClasses}>
          <div className={showSideRail ? mainContentSideRail : mainContent}>
            {sortedComponents.map(({ key }) => {
              switch (key) {
                case "whatYoullLearn":
                  return (
                    filteredExpectedLearningItems &&
                    filteredExpectedLearningItems.length > 0 && (
                      <WhatYoullLearn
                        key={key}
                        title="What you'll learn"
                        learningItems={filteredExpectedLearningItems}
                        areLearningItemsRTL={areLearningItemsRTL}
                      />
                    )
                  );
                case "programOverview":
                  return (
                    <ProgramContent
                      key={key}
                      title="Program overview"
                      fullDescription={programData.overview}
                      video={programOverviewVideo}
                    />
                  );
                case "programSummaryPathway":
                  return <ProgramSummaryPathway key={key} courses={courses} />;
                case "midPageCTA":
                  return programData.programEligibleForOneClickPurchase &&
                    programIsEnrollable ? (
                    <MidPageCTA
                      key={key}
                      programData={programData}
                      courses={courses}
                      skus={skus}
                    />
                  ) : null;
                case "instructorList":
                  return <InstructorList key={key} instructors={instructors} />;
                case "industryInsights":
                  return formattedInsights?.length > 0 ? (
                    <IndustryInsights key={key} insights={formattedInsights} />
                  ) : null;
                case "programEndorsements":
                  return programData.corporateEndorsements?.length > 0 ? (
                    <ProgramEndorsements
                      key={key}
                      corporateEndorsements={programData.corporateEndorsements}
                    />
                  ) : null;
                case "creditPathways":
                  return programData.creditRedemptionOverview ? (
                    <ProgramContent
                      key={key}
                      title="Certificate & credit pathways"
                      fullDescription={programData.creditRedemptionOverview}
                    />
                  ) : null;
                case "programEnroll":
                  return programData.programEligibleForOneClickPurchase &&
                    programIsEnrollable &&
                    pricingData &&
                    (pricingData.originalPrice ||
                      pricingData.discountedPrice) ? (
                    <ProgramEnroll
                      key={key}
                      courses={courses || []}
                      programData={programData}
                      duration={duration || null}
                      skus={skus}
                      pricingData={pricingData}
                    />
                  ) : null;
                case "programRelatedContent":
                  return (
                    <ProgramRelatedContent
                      key={key}
                      programSubject={programSubject}
                    />
                  );
                default:
                  return null;
              }
            })}
          </div>
        </div>
        {programData.hasOfacRestrictions && <OFACmessage />}
        <div>
          <SocialShare
            title="Share this program"
            productUrl={`${SITE_URL}/${programData.marketingUrl}`}
            productName={programData.title}
          />
          <div className="w-full flex justify-center relative -bottom-16 -mx-2">
            <div
              className={`${maxWidthContainer} ${fullWidthContainer} gap-8 absolute bottom-0`}
            >
              <ProgramHeroSideRailContainer
                headerRef={headerRef}
                scrollFollowerRef={scrollFollowerRef}
                sittingStillRef={sittingStillRef}
                scrollingLimitRef={scrollingLimitRef}
                isFollowingScroll={false}
                pricingData={pricingData}
                courses={courses}
                skus={skus}
                programData={programData}
                programIsEnrollable={programIsEnrollable}
              />
            </div>
          </div>
        </div>
        <div
          className={`bg-putty-300 w-full flex flex-col lg:h-64 justify-center items-center`}
          ref={scrollingLimitRef}
        >
          <div className={maxWidthContainer}>
            <CategoryCTA programSubject={programSubject} />
          </div>
        </div>
      </div>
    </>
  );
}
